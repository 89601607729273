import EmblaCarousel from "embla-carousel";

const onThumbClick = (mainCarousel, thumbCarousel, index) => () => {
  if (!thumbCarousel.clickAllowed()) return;
  mainCarousel.scrollTo(index);
  thumbCarousel.scrollNext();
};

const followMainCarousel = (mainCarousel, thumbCarousel) => () => {
	console.log(mainCarousel.selectedScrollSnap());
	thumbCarousel.scrollTo(mainCarousel.selectedScrollSnap());
	thumbCarousel.scrollNext();
};

var heroHome = document.getElementById("heroHome");

if(heroHome) {

	var emblaHeroHome = EmblaCarousel(heroHome, {
		loop: true
	});


	const thumbCarouselWrap = document.getElementById("homeHeroThumb");
	const thumbCarouselView = thumbCarouselWrap.querySelector(".home-hero-thumb_viewport");
	const thumbCarousel = EmblaCarousel(thumbCarouselView, {
		// selectedClass: "",
		containScroll: "keepSnaps",
		draggable: false,
		startIndex: 1,
		loop: true,
		inViewThreshold: 1,
		speed: 100
	});

	thumbCarousel.dangerouslyGetEngine().translate.toggleActive(false);

	thumbCarousel.slideNodes().forEach((thumbNode, index) => {
		// console.log(index);
		const onClick = onThumbClick(emblaHeroHome, thumbCarousel, index);
		thumbNode.addEventListener("click", onClick, false);
	});

	const syncThumbCarousel = followMainCarousel(emblaHeroHome, thumbCarousel);
	emblaHeroHome.on("select", syncThumbCarousel);

}
