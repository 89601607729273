import EmblaCarousel from "embla-carousel";

const wrapId = "homeEventsCarousel";
const wrap = document.getElementById(wrapId);

if (wrap) {
	const viewPort = wrap.querySelector(".events-carousel_viewport");

	const options = {
		containScroll: "trimSnaps",
		dragFree: true,
		draggable: true,
	};

	function init () {
		const embla = EmblaCarousel(viewPort, options);
		embla.on('init', (eventName) => {
			// jQuery(`#${wrapId} .card_desc-ellipsis`).ellipsis({ lines: 3, responsive: true });
			// jQuery(`#${wrapId} .card_title-ellipsis`).ellipsis({ lines: 2, responsive: true });
		})
	}

	init();
}


// import EmblaCarousel from "embla-carousel";
// import { breakpointDown, breakpointUp } from "./utils";

// // let embla;
// const wrap = document.getElementById("homeEventsCarousel");
// const viewPort = wrap.querySelector(".events-carousel_viewport");

// const mobileOptions = {
// 	containScroll: "trimSnaps",
// 	dragFree: true
// };

// const desktopOptions = {
// 	containScroll: "trimSnaps",
// 	dragFree: false,
// 	draggable: false,
// };

// function setOptionEmbla(embla) {

// 	if (breakpointDown('md')) {
// 		console.log('md');
// 		embla.reInit( mobileOptions );
// 	}

// 	if (breakpointUp('lg')) {
// 		embla.reInit( desktopOptions );
// 	}

// }

// function init () {
// 	const embla = EmblaCarousel(viewPort, desktopOptions);
// 	setOptionEmbla(embla);

// 	embla.on('resize', () => {
// 		setOptionEmbla(embla);
// 	});
// }



// init();

