import EmblaCarousel from "embla-carousel";

const wrapId = "homeRecommendationsCarousel";
const wrap = document.getElementById(wrapId);

if (wrap) {
	const viewPort = wrap.querySelector(".recommendations-carousel_viewport");

	const options = {
		containScroll: "trimSnaps",
		dragFree: true,
		align: 'start'
	};

	function init () {
		const embla = EmblaCarousel(viewPort, options);
		// embla.on('init', (eventName) => {
			// jQuery(`#${wrapId} .card_desc-ellipsis`).ellipsis({ lines: 3, responsive: true });
			// jQuery(`#${wrapId} .card_title-ellipsis`).ellipsis({ lines: 4, responsive: true });
		// })
	}

	init();
}