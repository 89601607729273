import EmblaCarousel from "embla-carousel";

const wrapClass = ".zoneSliderWithThumbs";
const wrapSlider = ".zoneSlider";
const wrap = document.querySelector(wrapSlider);
if (wrap) {
	const viewPort = wrap.querySelector(".zone-slider_viewport");

	const options = {
		containScroll: "trimSnaps",
		dragFree: false,
		startIndex: 0,
		align: 'start',
	};

	function init () {
		const embla = EmblaCarousel(viewPort, options);
	}

	init();
}