const header = document.querySelector(".site-header");

window.addEventListener("scroll", function(event) {
	let scroll = this.scrollY;

	header.classList.remove('is-scrolling')

	if (scroll > 0) {
		header.classList.add('is-scrolling')
	}

}, false);