window.addEventListener('load', function () {
  const CharmTabs = document.querySelectorAll(".CharmTabs");
  console.log(CharmTabs);
	// const tabs = CharmTabs.getElementsByClassName("charm-tab");
	
	CharmTabs.forEach(item => {
		console.log("ITEM",item)
		// const charm = item.getElementsByClassName("charm-tab");
		initCharmTabs(item);
	});
})

const initCharmTabs = (charmTab) => {
	const tabs = charmTab.querySelectorAll(".charm-tab");
	
	console.log(tabs);
	
	tabs.forEach(tab => {
		const header = tab.querySelectorAll('.charm-tab_header');
		console.log("header", header);
		addEventClickToTab(header);
	});
}

const addEventClickToTab = (header) => {

	header.addEventListener("click", (e) => {
		e.preventDefault();
		tabs.classList.remove("is-active");
		this.classList.add('is-active');
	});
}

