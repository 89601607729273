const zonesTabs = document.querySelectorAll(".zones-tabs");
const zonesSection = document.querySelectorAll("[data-macrozone]");

const onZoneTabButtonClick = (itemList, siblings) => {
	const btn = itemList.querySelector(".zone-button");
	btn.addEventListener("click", (e) => {
		e.preventDefault();
		zonesSection.forEach(obj => obj.classList.remove("is-active"));
		siblings.forEach(obj => obj.classList.remove("is-active"));
		itemList.classList.add('is-active');
		const dataMacrozoneId =  btn.getAttribute('data-macrozone')
		const selectMacrozone = document.querySelectorAll(`[data-macrozone='${dataMacrozoneId}']`);
		selectMacrozone.forEach(obj => obj.classList.add('is-active'));
	});
}

const addEventToItem = (items) => {
	items.forEach( item => onZoneTabButtonClick(item, items) );
}

zonesTabs.forEach((zoneTabs, index) => {
	const zonesTabsItems = zoneTabs.querySelectorAll(".zones-tabs_item");
	addEventToItem(zonesTabsItems);
});