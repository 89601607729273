import EmblaCarousel from "embla-carousel";
import { ellipsis } from 'ellipsed';
import { setupPrevNextBtns, disablePrevNextBtns } from "./emblaPrevNextButtons";

const wrapId = "homeItineraryCarousel";
const wrap = document.getElementById(wrapId);

const setEllipsis = (items) => {
	items.forEach(element => {
		ellipsis(element.querySelector(".card_title .card_title-ellipsis"), 4, {responsive: true, delimiter: '',});
	});
}

if (wrap) {
	const viewPort = wrap.querySelector(".itinerary-carousel_viewport");

	const options = {
		containScroll: "trimSnaps",
		dragFree: true,
		startIndex: 0,
		draggable: false,
		loop: true
	};

	function init () {
		const embla = EmblaCarousel(viewPort, options);
		const prevBtn = wrap.querySelector(".embla-button-prev");
		const nextBtn = wrap.querySelector(".embla-button-next");
		const disablePrevAndNextBtns = disablePrevNextBtns(prevBtn, nextBtn, embla);

		setupPrevNextBtns(prevBtn, nextBtn, embla);

		embla.on('init', (eventName, ev) => {
			setEllipsis(embla.slideNodes());
			disablePrevAndNextBtns();
		});

		embla.on("select", () => {
			const slides =  embla.slideNodes();
			const slideSelected = slides[embla.selectedScrollSnap()];
			const card = slideSelected.querySelector('.itinerary-card');
			console.log(slides);
			slides.forEach(obj => {
				const card = obj.querySelector(".itinerary-card");
				card.classList.remove("is-active");
			});
			
			card.classList.add('is-active');
			setEllipsis(embla.slideNodes());

		});
	}

	init();
}