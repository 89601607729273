const menuTrigger = document.getElementById("menuTrigger");
const ShowSearchBarBtns = document.getElementsByClassName("btnShowSearchBar");
const btnHideSearchBar = document.getElementById("btnHideSearchBar");


function onClick(event) {
	document.body.classList.toggle("menuIsOpen");
}

menuTrigger.addEventListener("click", onClick, false);



function showSearchBar(event) {
	document.body.classList.add("searchOpened");
}

function hideSearchBar(event) {
	document.body.classList.remove("searchOpened");
}

btnHideSearchBar.addEventListener("click", hideSearchBar, false);

Object.values(ShowSearchBarBtns).forEach(btn => {
	btn.addEventListener("click", showSearchBar, false);
});